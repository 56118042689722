<template>
     <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" class="lg:mx-40 mx-7 rounded-2xl bg-blueNew1 lg:my-20 my-14 mt-10 lg:pb-4 pb-10">
     <div class="lg:w-auto bg-blueNew1 text-white lg:flex lg:justify-center lg:items-stretch lg:mt-0 w-80 min-h-[33rem] mt-12 mb-12 shadow-[0_2.5rem_2rem_#00000030] relative  rounded-3xl">
     <div class="text-center text-2xl">Mentions légales</div>   <br/> <br/>
     <div class="text-left text-xl">
Article 1 – Mentions légales <br/> <br/>
1.1 Site (ci-après « le site ») : <br/> <br/>
La société Tag' n Go – www.tagango.com <br/> <br/>

1.2 Éditeur (ci-après « l’éditeur ») : <br/> <br/>

La société BforProcess SAS, au capital de 53 445 €, dont le siège social est situé : 128 rue de la Boétie 75008 Paris <br/> <br/>
représentée par David GUITON, en sa qualité de président, immatriculée au RCS de Paris B 904 107 810. <br/> <br/>

Directeur de la publication : Romaric Tchokpon.  <br/> <br/>

Adresse mail : contact@tagango.com. <br/> <br/>

Téléphone : 78-124-22-11. <br/> <br/>

1.3 Hébergeur (ci-après « l’hébergeur ») : <br/> <br/>

Tag'n Go  (www.tagango.com) est hébergé par Linode (AWS), dont le siège social est situé : 38 AV JOHN F KENNEDY L 1855 99137 LUXEMBOURG. </div> <br/>
    </div>
  </div> 
</template>

<script>

export default {
  name: "index",

  components: {
    

    },
}

</script>
